.affiliateprogramsec {
  background-image: url(../../../Assests/Bannar/background-texture.svg) !important;
  text-align: center;
  background: #fbf7f3;

  .Bbenefits {
    .toperparraa {
      display: flex;
      border-radius: 20px;
      max-width: 654px;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      margin: 0 auto;

      .commonheading {
        color: #ff8a00;
        font-size: 16px;
        font-weight: 500;
        line-height: 110%;
      }

      .besthngd {
        color: #10312b;
        text-align: center;
        font-size: 32px;
        font-weight: 600 !important;
        line-height: 110%;
      }
    }

    .onlyfor_cards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      padding-top: 32px;

      .cardds {
        flex: 0 0 24%;
        min-width: 226px;
        height: 208px;
        padding: 25px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border: 1px solid #efefef;
        background: #fff;
        position: relative;
        cursor: pointer;

        &:hover {
          border: 1px solid #ff8a00;

          .tooltip-content {
            display: block;
            z-index: 10;
          }
        }

        .iconqus {
          position: absolute;
          right: 15px;
          top: 15px;
          background: #fff;
          border: none;

          .info-icon {
            transition: transform 0.2s ease-in-out;
          }

          &:hover .info-icon {
            transform: scale(1.1);
          }
        }

  

        .tooltip-content {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background: #fff;
          border: 1px solid #ff8a00;
          border-radius: 12px;
          // padding: 15px;
          z-index: 10;
          // opacity: 1;
          // min-width: 226px !important;


        }
      }
    }
  }
}
.inercard_text {
  color: #10312b;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 12px;
}

.inerpara {
  color: #586f6b;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  margin: 0;
}
/* Tooltip arrow */
.tooltip-arrow::before {
  border-width: 5px;
  border-color: transparent transparent #ff8a00 transparent !important;
}

/* Tooltip arrow for bottom tooltip */
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #ff8a00 transparent !important;
}

.tooltip {
  border-radius: 12px;
  color: #10312b;
  min-width: 250px !important; /* Increased min-width for hover box */
  text-align: center;
  z-index: 10; /* Make sure it's visible above other elements */
}

.tooltip-inner {
  padding: 10px !important;
  background-color: white !important;
  color: #000 !important;
  width: 100%;
  max-width: 300px !important; /* Increased max-width */
}

.tooltip-data {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tooltip.show {
  opacity: unset !important;
}

/* Arrow positioning */
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 10; /* Ensure arrow is on top */
}

.tooltip.bs-tooltip-top .tooltip-arrow {
  bottom: -4px;
  /* width: 16px; */
  height: 9px;
  left: 45%;
  margin-left: -5px;
  border-width: 8px 9px 1px 9px;
  border-color: #ff8a00 transparent transparent transparent;
  transform: translate3d(152px, -2px, 0px) !important;
}

// .tooltip.bs-tooltip-top .tooltip-arrow {
//   bottom: -5px;
//   left: 50%;
//   margin-left: -5px;
//   border-width: 5px 5px 0 5px;
//   border-color: #ff8a00 transparent transparent transparent;
// }

/* Adjusted padding and gap in tooltip inner */
.tooltip-inner {
  padding: 15px !important; /* Adjusted padding for better readability */
  background-color: white;
  color: #000;
  text-align: center;
  border-radius: 12px;
  border: 1px solid #ff8a00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px !important;
}



@media (max-width: 800px) {
  .onlyfor_cards .cardds {
    flex: 0 0 30%;
    padding: 20px 10px;
  }

  .commonheading {
    font-size: 22px !important;
  }

  .toperparraa {
    gap: 15px;
  }

  .besthngd {
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .affiliateprogramsec .Bbenefits .onlyfor_cards .cardds {
    flex: auto;
  }
  
  .onlyfor_cards .cardds {
    flex: auto;
    width: 100%;
  }
  .tooltip.bs-tooltip-top .tooltip-arrow {
    bottom: -4px;
    /* width: 16px; */
    height: 9px;
    left: 50%;
    margin-left: -5px;
    border-width: 8px 9px 1px 9px;
    border-color: #ff8a00 transparent transparent transparent;
    transform: translate3d(258px, -2px, 0px) !important;
}
}
