.main-footer {
  .innnnner_footer {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  padding: 30px 50px;
  // border-radius: 16px;
  background: #fff;
  margin-bottom: 10px;
  margin: 0px 10px;
  border-radius: 20px;
  margin-top: 20px;
  // margin-right: 10px ;
  // margin-left: 10px ;

  .fotrmaindiv {
    display: flex;

    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding-bottom: 0px;
    .fotrlinks {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 28px;
      p.ftrpara {
        color: #10312b;
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
        margin: 0px auto;
      }
    }
    .ftrsocallinks {
      display: flex;
      align-items: center;
      gap: 25px;
      p.btmpataftr {
        color: #10312b;
        text-align: center;
        // font-family: Aeonik;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 14.4px */
        text-transform: capitalize;
      }
    }
  }
  .btmftor {
    border-top: 1px solid #d1d1db;
    width: 100%;
    padding: 20px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.ftrpara {
  color: #10312b !important;
  text-align: center;

  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  margin: 0px auto;
}
@media (max-width: 600px) {
  .main-footer .fotrmaindiv .fotrlinks {
  }
  .ftrsocallinks {
    flex-direction: column !important;
  }
  .fotrmaindiv {
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 1100px) {
  .fotrmaindiv {
    flex-direction: column;
    gap: 20px;
  }
}
