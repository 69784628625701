// @import url("https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&display=swap");

// @font-face {
//   font-family: FRegular;
//   src: url(../src/font/Fontspring-DEMO-Acronym-Regular.otf);
// }

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Rajdhani", sans-serif !important;
  background: #FBF7F3 !important;
  min-height: 100vh;
  // background: #111213cc;
  margin: 0px;
  padding: 0px;
}

wcm-modal {
  z-index: 999999999;
}

h2 {}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

a {
  // color: var(--bs-link-color);
  text-decoration: none !important;
}

a:hover {
  // color: #00C9FF !important;
  text-decoration: none !important;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.custom-container {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

h1 {
  margin: 0px;
}

h2 {
  margin: 0px;
}

h3 {
  margin: 0px;
}

h4 {
  margin: 0px;
}

h5 {
  margin: 0px;
}

h6 {
  margin: 0px;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

h1 {
  // font-size: 55px !important;
  // font-style: normal !important;
  // font-weight: bold !important;
  // text-transform: uppercase !important;
  // font-family: "Goldman", serif !important;
}

.grey {
  color: #888 !important;
}

.grey2 {
  color: #475569;
}

.ptb20 {
  padding: 20px 0;
}

p {
  margin: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #1777e2;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background: #111213;
  }
}

.twinsbtnssfooter {
  display: flex;
  width: 34px;
  height: 34px;
  /* padding: 12px 16px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 37px;

  border: 1.5px solid rgba(16, 49, 43, 0.12);

  &:hover {
    color: #ff8a00;
    // background: #0f1a1e;
    transition: 0.5s ease-in-out;
    box-shadow: 1px #ff8a00;
    // border-radius: 6px;
    // border: 1.5px solid #0076F9;
    border: 1.5px solid #ff8a00;
  }
}

.commonheading {
  color: #ff8a00;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}

.commonbtnorg {
  border-radius: 100px;
  background: #ff8a00;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 26.4px */
  text-transform: uppercase;
  padding: 27px 54px;
  // left: -1.088px;
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    // background-color: transparent;
    // border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    // font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .commonbtnorg {
    border-radius: 100px;
    background: #ff8a00;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 26.4px */
    text-transform: uppercase;
    padding: 17px 34px;
    // left: -1.088px;
  }

  .xs-0 {
    padding: 0px;
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }

  // 12 pro max
  @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    .nav-brand {
      margin-left: 15px;
    }
  }

  .dnnf {
    text-align: end !important;
  }

  iframe {
    position: unset !important;
    top: 0px !important;
    left: 0px !important;
    width: 0px !important;
    height: 0px !important;
  }

  button {
    cursor: pointer;
  }

  .btn-loadmore button {
    transition: 0.7s;

    &:hover {
      background: #f41e5e !important;
      color: #fff !important;
    }
  }
}

@media (max-width: 600px) {
  h2 {
    // font-size: 30px !important;
  }

  .padd-sm {
    padding: 0 !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.70);

  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* Ensure loader is on top of all content */
}

.spinner {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-radius: 50%;
  border-top: 8px solid #3498db;
  /* Blue color for spinner */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}