.Roadmaps {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #fbf7f3;
  background-image: url(../../../Assests/Bannar/background-texture.svg) !important;
  .maincarddiv{
    padding: 10px;
    height: 398px;
  }
  .inner_banner {
    display: flex;
    padding: 2px;
    flex-direction: column;
    align-items: flex-start;

    border: 1px solid #fff;
    border-radius: 12px;
    background: #fff;
  height: 100%;
    // margin-right: 5px !important;
    &:hover {
      // border-radius: 12px;
      border: 1px solid #ff8a00;
      background: #fff;
    }
    .inner_part {
      // max-width: 331px;
      width: 100%;
      // height: 210px;
      border-radius: 12px;
      img.imgback {
        border-radius: 12px;
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
    .right_side {
      display: flex;
      padding: 15px 10px;
      flex-direction: column;
      align-items: flex-start;
      // gap: 30px;
      // align-self: stretch;
      .phase111 {
        color: #10312b;
        /* font-family: Aeonik; */
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        margin: 0px;
      }
      .datttrers {
        color: #10312b;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
      }
      .fantomms {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7px;
        padding-top: 20px;
        padding-left: 20px;
        li::marker {
          color: #ff8a00;
        }
        .ioucserd {
          color: #10312b;

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
          list-style-type: disc;
        }
      }
    }
  }

  .card_mains {
    display: flex;
    align-items: center;
    gap: 12px;
    overflow-y: auto;
    // width: 1440px;
  }
  .toperparxraa {
    display: flex;
    max-width: 654px;
    flex-direction: column;
    align-items: center;
    /* gap: 16px; */
    width: 100%;
    justify-content: center;
    margin: 0px auto;
    padding-bottom: 50px;
    .commonhessdsading {
      color: #ff8a00;

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 17.6px */
    }
    .dsdsds {
      color: #10312b;
      text-align: center;

      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 35.2px */
      padding-top: 16px;
      padding-bottom: 30px;
      margin: 0px;
    }
    .seccssdsparaa {
      color: #10312b;
      text-align: center;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 21.6px */
      text-align: center;
    }
  }
}
.slick-prev:before,
.slick-next:before {
}
.slick-next {
  right: -25px;
}
.myyynewdiv {
  // padding: 0px 0px 0px 20px;
}
::-webkit-scrollbar {
  display: none;
}
/* Default dot color */
.slick-dots li button:before {
  color: #000000 !important; /* Inactive dot color */
}

/* Active dot color */
.slick-dots li.slick-active button:before {
  color: #ff8a00 !important; /* Change active dot color to orange */
}
.slick-prev {
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 45px !important;
  height: 45px !important;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #ff8a00 !important;
  border: 1px solid black;
  outline: none;
  // background:  unset!important;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #ff8a00 !important;
  outline: none;
  border: 1pz solid #ff8a00;
  background: transparent !important;
}
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: unset !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: unset !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -35px !important;
}
.slick-next {
  right: -35px !important;
}
@media (max-width: 600px) {
  .maincarddiv {
    // margin-left: 10px;
  }
  .carousel-item {
    display: grid;
    flex-wrap: nowrap;
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  .dsdsds {
    font-size: 24px !important;
  }
  .myyynewdiv {
    padding: 0px 0px !important;
  }
}
@media (max-width: 850px) {
  .Roadmaps .maincarddiv{
    padding: 0 !important;
  }
  .commonhessdsading {
    font-size: 22px !important;
    padding-bottom: 14px !important;
  }
  .card_mains {
    width: 100% !important;
  }
  .Roadmaps h3 {
    color: var(--Black1, #000);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .Roadmaps li {
    color: var(--Body-text, #555);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }
 
  .Roadmaps {
    margin: 0px 0px;
    padding-top: 40px !important;
    padding-bottom: 20px !important;
    .right_side {
      padding: 20px;
    }
    .inner_banner {
      // flex-direction: column-reverse;
      width: 100%;
      // padding: 20px;
      max-width: 100%;
    }
    .inner_part {
      // padding: 0px;
      border: 0px;
      // margin-bottom: 10px;
    }
    .imgback {
      width: 100%;
      height: unset;
    }
    .right_side {
      padding-left: 15px !important;
      width: 100%;
      padding-bottom: 10px !important;
    }
    .carousel-item {
      display: grid;
      flex-wrap: nowrap;
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;
    }
  }
  .slick-next{
    right: 42% !important;
    width: 20px !important;
    height: 20px !important;
    top: unset !important;
    bottom: -40px !important;
  }
  .slick-prev{
    left: 42% !important;
    width: 20px !important;
    height: 20px !important;
    top: unset !important;
    bottom: -40px !important;
  }
}
@media (max-width: 1500px) {
  .card_mains {
    width: 100% !important;
  }
}
