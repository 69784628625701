.Riyadh {
  background-color: rgb(255, 255, 255);
  padding-bottom: 80px;
padding-top: 0px;
  height: auto;

  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;

  .tohngfsd {
    display: flex;
    /* width: 560px; */
    flex-direction: column;
    align-items: center;
    gap: 22px;
  }
  .rigDHBTNN {
    border-radius: 130px;
  
    border: 1.5px solid rgba(16, 49, 43, 0.12);
    background: #fff;
    backdrop-filter: blur(2.5px);
    white-space: nowrap;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #10312b;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    max-width: 207px;
    width: 100%;
    margin: 0px auto;
    margin-top: 30px;
  }
  .owlcoaurzll {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h2 {
    color: #10312b;
    text-align: center;

    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 50px */
    padding-bottom: 60px;
    text-transform: capitalize;
  }
  .imgdivv {
    // max-width: 532px;
    // width: 100%;
    // height: 404px;
  }
  .inner_div {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    margin: 0px 210px;
    border-radius: 20px;
border: 1px solid rgba(16, 49, 43, 0.08);
background: #FFF;
overflow: hidden;
  }
  h3 {
    color: #000;
    text-align: center;
    // padding-bottom: 20px;
    font-size: 40px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
   
  }
  .pirceinerdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .fstminbld {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .mntgp {
        color: #10312b;
        text-align: center;
        /* font-family: Aeonik; */
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
      p.mntgpcv {
        color: #10312b;
        text-align: center;
        /* font-family: Aeonik; */
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }
  .mintingimggg {
    width: 100%;
  }
  .imgdivv{
    width: 100%;
  }
  .muttedds {
    color: #6f827f;
    /* font-family: Aeonik; */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .right_side {
    max-width: 532px;
    width: 100%;
    // height: 404px;

    background: #fff;
    display: flex;
    // width: 532px;
    padding: 40px 50px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    // border: 0.5px solid ;
  }
  h4 {
    color: #000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .partsecond {
    display: flex;
    gap: 10px;
    // margin-top: 22px;
    align-items: center;
  }
  .plusssdiv {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    border-radius: 56px;
    border: 2px solid #ebebeb;
    background: #fff;
    gap: 10px;
    margin-top: 0px;
  }
  h5 {
    width: 13.72px;
    height: 13.776px;
  }
  .inputmint {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    height: 80px;
    margin-bottom: 0px;
    border-radius: 61px;
    border-radius: 100px;
    border: 2px solid #ebebeb;
    background: #fff;
    text-align: center;

    text-align: center;
    color: #10312b;

    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    ::placeholder {
      color: #10312b;
      text-align: center;

      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
      text-align: center;
    }
  }
  .commonbtnorgss {
    color: #fff;
    text-align: center;

    font-style: normal;
    border-radius: 60px;
    background: #ff8a00;

    border: unset;
    width: 100%;
    display: flex;
    padding: 30px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    text-align: center;

    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.88px;
    text-transform: capitalize;
  }
  .headtex {
    color: #000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600 !important;
    background-color: unset;
    line-height: normal;
    text-transform: capitalize;
    white-space: nowrap;
  }
}
.leftrightmarginonly {
  // margin: 0px 17px;
}
@media (max-width: 1200px) {
  .right_side {
    width: 100% !important;
    max-width: none;
  }
  .inner_div{
    margin: 0px !important;
  }
  .Riyadh {
    height: max-content;

    padding: 30px;
    margin: -25px 0px 40px 0px;

    width: 100%;
    border-radius: 0px;
  }
  .Riyadh .inner_div {
    flex-direction: column;
  }

  .Riyadh {
    .imgdivv {
      width: 100%;
      height: unset;
    }
    h4 {
      font-size: 18px;
    }
    .plusssdiv {
      height: 40px;
      width: 40px;
    }
    h6 {
      height: 40px;
    }
    button {
      height: 60px;
      font-size: 18px;
    }
    .headtex {
      font-size: 20px;
    }
  }
}
@media (max-width: 850px) {
  .Riyadh {
    .inner_div {
      flex-direction: column;
    }
    .right_side {
      width: 100%;
    }
    h2 {
      font-size: 35px;
      padding-top: 20px;
    }
  }
}
@media (max-width: 650px) {
  .Riyadh .rigDHBTNN {
    font-size: 18px;

    width: fit-content;

    white-space: nowrap;
  }
  .Riyadh .commonbtnorgss{
    padding: 7px 7px !important;
    white-space: nowrap;
    width: 100% !important;
  }
  .right_side {
    padding: 40px 33px !important;
  }
  .Riyadh .inputmint {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex: 1 0;
    height: auto;
    margin-bottom: 0px;
    border-radius: 61px;
    border: 1px solid #000;
    background: #fff;
    text-align: center;
    color: #000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
}
