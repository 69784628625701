.buynow_main {
  // padding-top: 146px;
  background: #FBF7F3;
  text-align: center;

  .Bbenefits {
    .toperparraa {
      display: flex;
      // width: 594px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding-bottom: 146px;
      .besthngd {
        color: var(--Black1, #000);
        /* font-family: Acronym; */
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
      }
      p.seccsparaa {
        color: var(--SubText, #7a7a7c);
        text-align: center;
        /* font-family: Acronym; */
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        // width: 50%;
      }
    }
  }
}
.mainbanner_infoss {
  padding-top: 146px;
  padding-bottom: 146px;

  margin: 0px auto;

  background-image: url("../../../Assests/byunow/buynowbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 16px;
  .newinoeart {

    display: flex;
  

  
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
   
    margin: 0px auto;
    .apple {
      color: #FFF;
      text-align: center;
   
      font-size: 46px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 50.6px */
    }
    .buynowbtn {
      border-radius: 100px;
      background: #FF8A00;
      padding: 17px 54px;
      color: var(--White, #fff);
      color: #FFF;
      text-align: center;
 
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
      border: 1px solid #FF8A00 ;
      &:hover {
        color: #FF8A00;
        // background: #0f1a1e;
        transition: 0.5s ease-in-out;
        background: #fff;
        ;
        // border-radius: 6px;
        // border: 1.5px solid #0076F9;
        border: 1px solid #FF8A00;
       
      }
    }
  }
}

@media (max-width: 600px) {
  .affiliateprogram .mypara {
    margin: 0px 0px 18px;
  }
  .affiliateprogram .myparahead {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .affiliateprogram .listmain {
    margin-bottom: 10px;
    font-size: 14px !important;
  }
}
@media (max-width: 800px) {
  .buynow_main {
    padding-top: 50px;
    text-align: center;
  }
  .mainbanner_infoss {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .mainbanner_infoss .newinoeart {
    width: 80%;
    margin: 0px auto;
  }
  .mainbanner_infoss .newinoeart .apple {
    font-size: 30px;
  }
}
