.joinhead {
  border-radius: 20px;
  background: #fff;
  .joinmain {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 22px;

    .ypoierrt {
      color: #10312b;
      font-size: 16px;
      font-style: normal;
      width: 193px;
      white-space: nowrap;
      font-weight: 400;
      line-height: 110%; /* 17.6px */
      padding: 10px 18px;
      border-radius: 130px;
      border: 1.5px solid rgba(16, 49, 43, 0.12);
      background: #fff;
      backdrop-filter: blur(2.5px);
      text-align: center;
    }
    h1 {
      color: #10312b;
      text-align: center;
      font-size: 90px;
      font-style: normal;
      font-weight: 700;
      line-height: 90%; /* 81px */
      text-transform: uppercase;
      text-align: center;
      width: 542px;
    }
    h2 {
      color: #586f6b;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 21.6px */
      width: 390px;
      padding-top: 36px;
    }
    button {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
      display: flex;
      width: 194px;
      padding: 17px 14px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 37px;
      background: #ff8a00;
      border: unset;
      margin-bottom: 244px;
    }
    .imgleftt {
      position: absolute;
      left: 120px;
      top: 274px;
    }
    .imgright {
      position: absolute;
      right: 120px;
      top: 274px;
    }

    .backkkimg {
      position: absolute;
      width: 100%;
      top: 239px;
        padding: 0px 10px;
    }
  }
  .forscroolbakkh {
    display: inline-flex;
    transform: rotate(-4.622deg);
    padding: 20px 45px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: #ff8a00;
  
    .inernfttpara {
      color: #fff;
      text-align: center;
      font-family: Rajdhani;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 20px;
      margin-right: 20px !important;
    }
  }
//   .onlydivscroolingdiv{
//     display: flex;
//     // flex-direction: column;
//     animation: scroll-left 30s linear infinite;
//     white-space: nowrap;
//     overflow: hidden;

//   }
//   @keyframes scroll-left {
//     0% {
//       transform: translateX(100%);
//     }
//     100% {
//       transform: translateX(-100%);
//     }
//   }
}

.onlydivscroolingdiv {
  display: flex;
  animation: scroll-left 30s linear infinite;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; /* Ensure it takes full width */
}

.scroll-content {
    display: flex;
    justify-content: space-between;
    align-items: center;/* Make sure the content behaves as an inline element */
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move it halfway to the duplicated content */
  }
}
@media (max-width: 900px) {
    .joinhead .joinmain h1 {
        font-size: 31px;
        width: 100%;
    }
    .joinhead .joinmain h2 {
        width: 100%;
    }
    .joinhead .joinmain .imgleftt {
        position: absolute;
        left: 163px;
        top: 436px;
        width: 212px;
    }
    .joinhead .joinmain .imgright {
        position: absolute;
        right: 165px;
        top: 436px;
        width: 224px;
    }
    .joinhead .joinmain {
        padding-top: 100px;
    }
}