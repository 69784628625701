.ourteam {
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url(../../../Assests/Bannar/background-texture.svg) !important;

  text-align: center;
  background: #fbf7f3;

  .rentbutton {
    color: #ff8a00;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 14.4px */
    text-transform: uppercase;
    padding: 10px 11px;
    border-radius: 100px;
    background: #fff !important;
    border: unset;
    top: 10px;
    left: 10px;
    position: absolute;
  }

  // padding-left: 25px;
  // padding-right: 25px;
  .mainouterdivproperties {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    // align-items: center;
    padding-bottom: 50px;

    .rightfstt_aboutvvccc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;

      .aboutdfdvv {
        color: #ff8a00;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        margin: 0px;
      }

      .myparxxa {
        color: #10312b;

        font-size: 32px;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 110%;
        margin: 0px;
      }

      .myparasefrecsde {
        color: #10312b;

        font-size: 18px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 120%;
        max-width: 654px;
        text-align: start;
        width: 100%;
        margin: 0px;
      }
    }

    button.nnmoreproperties {
      display: flex;
      padding: 12px 15px;
      justify-content: center;
      align-items: center;
      gap: 7px;
      border-radius: 110px;
      background: #ff8a00;
      color: #fff;
      text-align: center;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      text-transform: uppercase;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid #ff8a00;
        background: transparent;
        color: #ff8a00;
      }
    }
  }

  h3.teamheading {
    color: var(--Black1, #000);
    text-align: start;

    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    padding-bottom: 36px;
  }

  .ourteammainouter_div2 {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // gap: 14px;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;

    .maincarddiv {
      //       max-width: 388px;
      // height: 284px;
      width: 100%;
      border-radius: 12px;
      background: #fff;
      position: relative;
      padding: 2px;
      border: 1px solid #fff;

      &:hover {
        border: 1px solid #ff8a00;
      }

      .imgcardddds {
        width: 100%;
        height: auto;
        border-radius: 10px;
        object-fit: fill;
        background-position: center;
        object-position: center;
        // max-width: 388px;
        height: 284px;
      }

      .zoom-container {
        position: relative;
        width: 100%;
        /* Adjust width as needed */
        overflow: hidden;
        /* Hide the part of the image that exceeds the container */
      }

      /* Image styling */
      .imgcardddds {
        display: block;
        width: 100%;

        transition: transform 0.5s ease;
        /* Smooth transition for the zoom effect */
      }

      /* Zoom effect on hover */
      .zoom-container:hover .imgcardddds {
        transform: scale(1.2);
        /* Adjust scale factor as needed */
      }

      .inercard_dta {
        padding: 15px 10px;
      }

      h1 {
        color: #10312b;
        text-align: start;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 0px;
        line-height: 140%;
        /* 33.6px */
      }

      p {
        color: #586f6b;
        text-align: start;
        padding-bottom: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        padding-top: 3px;
      }

      .iner_mainsssss {
        display: flex;
        align-items: center;
        gap: 50px;
        justify-content: start;
      }

      h2 {
        color: #586f6b;
        margin-bottom: 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 14px */
      }

      .cardds {
        display: flex;

        align-items: center;
        gap: 10px;
      }

      .imgsvgss {
        width: 24px;
        height: 24px;
      }

      .botttom_side {
        margin-top: 15px;
        border-top: 1px solid #efefef;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      h3 {
        color: #10312b;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 30.8px */
      }

      button {
        color: #ff8a00;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 7px;
        background-color: unset;
        border: unset;
        outline: unset;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 14.4px */
        text-transform: uppercase;
      }
    }

    .card {
      border-radius: 12px;
      background-color: #fff;

      .card-body {
        transition: unset !important;
        display: flex;
        padding: 15px 10px;
        flex-direction: column;
        // align-items: flex-start;
        // gap: 30px;
        align-self: stretch;
      }

      .card-title.h5 {
        color: #10312b;

        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 33.6px */
        // margin: 0px;
        text-align: start;
      }

      p.card-text {
        color: #586f6b;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        // padding: 0px 0px 0px 3px;
        text-align: start;
      }

      .incardflexdiv {
        display: flex;
        align-items: center;
        gap: 7px;
        /* align-self: stretch; */
        width: 100%;
        justify-content: space-between;

        .textwithimg {
          display: flex;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          color: #586f6b;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }

      .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* align-self: stretch; */
        padding-top: 15px;
        background-color: #fff;

        .cadftrdig {
          color: #10312b;

          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        .seedetails {
          color: #ff8a00;
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
        }
      }
    }

    .titlecardddssg {
      display: inline-flex;
      padding: 10px 11px;
      justify-content: center;
      align-items: center;
      gap: 7px;
      border-radius: 100px;
      background: #fff;
      color: #ff8a00;
      text-align: center;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      text-transform: uppercase;
    }
  }
}

.svgorange:hover {
  fill: #ff8a00 !important;
}

.svgorange path {
  stroke: #fff;
  /* Default color */
  transition: stroke 0.3s ease;
  /* Smooth transition effect */
}

.svgorange:hover path {
  stroke: #ff8a00;
  /* Color on hover */
}

@media (max-width: 991px) {
  //   .ourteam{
  // padding: 20px !important;
  //   }
  .ourteam .ourteammainouter_div .card .card-title.h5 {
    color: #181818;
    text-align: center;
    /* font-family: Acronym; */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .ourteam .ourteammainouter_div {
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 10px;
  }

  .ourteam .ourteammainouter_div .card p.card-text {
    color: #383751;
    /* font-family: Acronym; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .ourteammainouter_div2 {
    grid-template-columns: 1fr 1fr !important;
    flex-wrap: wrap;
  }

  // .ourteam .ourteammainouter_div {
  //   flex-wrap: wrap;
  //   align-items: center;
  //   justify-content: center;
  // }
  .ourteam {
    /* padding-bottom: 50px; */
    /* padding-top: 50px; */
    text-align: center;
    /* padding-left: unset; */
    padding: 50px 0px 50px 0px;
    /* padding-right: 25px; */
  }

  .ourteam h3.teamheading {
    font-size: 30px;

    padding-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .ourteam {
    padding: 0px 10px !important;
  }

  .ourteam .ourteammainouter_div .card p.card-text {
    font-size: 12px !important;
  }

  .myparasefrecsde {
    align-items: center !important;
    text-align: center !important;
  }

  .myparxxa {
    white-space: nowrap !important;
    font-size: 24px !important;
  }

  .ourteammainouter_div2 {
    grid-template-columns: 1fr !important;
  }

  .nnmoreproperties {
    margin-top: 25px !important;
  }

  .ourteam .ourteammainouter_div .card .card-title.h5 {
    font-size: 16px !important;
  }

  .ourteam .ourteammainouter_div {
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr !important;
    gap: 10px;
  }

  .affiliateprogram .mypara {
    margin: 0px 0px 18px;
  }

  .affiliateprogram .myparahead {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .affiliateprogram .listmain {
    margin-bottom: 10px;
    font-size: 14px !important;
  }

  .mainouterdivproperties {
    flex-direction: column !important;
  }

  .rightfstt_aboutvvccc {
    align-items: center !important;
    text-align: center !important;
  }

  .nnmoreproperties {
    margin: 0 auto;
  }
}
