.Connecthead {
    border-radius: 20px;
    background: #FFF;

    .joinmain {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 22px;

        p {
            color: #10312B;
            font-size: 16px;
            font-style: normal;
            width: 193px;
            white-space: nowrap;
            font-weight: 400;
            line-height: 110%;
            /* 17.6px */
            padding: 10px 18px;
            border-radius: 130px;
            border: 1.5px solid rgba(16, 49, 43, 0.12);
            background: #FFF;
            backdrop-filter: blur(2.5px);
            text-align: center;
        }

        h1 {
            color: #10312B;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 90%; /* 36px */
            text-transform: uppercase;
            text-align: center;
            width: 454px;
        }
h4{
    color: #10312B;
text-align: center;

font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 90%; /* 18px */
text-transform: uppercase;
}
        h2 {
            color: #586F6B;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 21.6px */
            width: 390px;
           

        }
        .innnnnput{
            border-radius: 8px;
border: 1.5px solid rgba(16, 49, 43, 0.12);
background: #FFF;
backdrop-filter: blur(2.5px);
display: flex;
width: 385px;
padding: 18px;
align-items: center;
gap: 10px;
color: #000;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 110%; /* 17.6px */
        }
        ::placeholder{
            color: rgba(16, 49, 43, 0.30);

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 110%; /* 17.6px */
        }

        button {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 19.2px */
            text-transform: uppercase;
            display: flex;
            width: 194px;
            padding: 17px 14px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 37px;
            background: #FF8A00;
            border: unset;
            margin-bottom: 244px;
        }

        .imgleftt {
            position: absolute;
            left: 120px;
            top: 274px;

        }

        .imgright {
            position: absolute;
            right: 120px;
            top: 274px;
        }

        .backkkimg {
            position: absolute;
            width: 100%;
            top: 239px;
            padding: 0px 10px;
        }
        .container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          
          /* Hide the browser's default checkbox */
          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          
          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #fff;
            border: 1px solid #FF8A00;
            border-radius: 4px;
          }
          
          /* On mouse-over, add a grey background color */
          .container:hover input ~ .checkmark {
            background-color: #fff;
          }
          
          /* When the checkbox is checked, add a blue background */
          .container input:checked ~ .checkmark {
            background-color: #FF8A00;
          }
          
          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          
          /* Show the checkmark when checked */
          .container input:checked ~ .checkmark:after {
            display: block;
          }
          
          /* Style the checkmark/indicator */
          .container .checkmark:after {
            left: 8px;
            top: 2px;
            width: 8px;
            height: 14px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          .innnnerpart{
            border-radius: 135px;
border: 1px solid rgba(16, 49, 43, 0.12);
backdrop-filter: blur(80px);
display: flex;
padding: 15px;
justify-content: space-between;
cursor: pointer;
align-items: center;
flex: 1 0 0;
&:hover{
    border: 1px solid #FF8A00;
}
          }
          .paraaa1{
            display: flex;
align-items: center;
gap: 20px;
border: unset;
padding: unset;
color: #000;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 16px */
          }
          .mainnnndevv{
            display: flex;
            width: 350px;
            align-items: flex-start;
            gap: 15px;
            justify-content: center;
          }
    }
}
.ambmodalmain{
    .modal-body {
        padding: 0px;
        .myyyydiv{
            display: flex;
            padding: 50px;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
            align-items: center;
    
            border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 50px 20px -30px rgba(0, 0, 0, 0.10);
    .imgsvg{
        width: 213px;
    height: 213px;
    }
    h1{
        color: #000;
    
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 42.9px */
    text-transform: uppercase;
    }
    p{
        color: #000;
    text-align: center;
    padding-bottom: 20px;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 32.5px */
    }
    button{
        color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 19.2px */
    text-transform: uppercase;
    display: flex;
    width: 194px;
    padding: 17px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 37px;
    border: unset;
    background: #FF8A00;
    }
        }
      
    }


}
@media (max-width: 900px) {
    .Connecthead .joinmain h1 {
    width: 100%;
    font-size: 28px;
    }
    .Connecthead .joinmain .innnnnput {
        width: 320px;
    }
    .Connecthead .joinmain .imgright {
        position: absolute;
        right: 149px;
        top: 604px;
        width: 254px;
    }
    .Connecthead .joinmain .imgleftt {
        position: absolute;
        left: 147px;
        top: 604px;
        width: 239px;
    }
    .Connecthead .joinmain .mainnnndevv {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Connecthead .joinmain {
        margin-top: 50px;
    }
}