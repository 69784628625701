.affiliateprogram {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #FBF7F3;
  .left-img{
    border-radius: 30px;
    // padding-left: 25px !important;
    // margin-left: 30px !important;
  }
.smartcityouterdiv{
  display: flex;
  align-items: flex-start;
  gap: 134px;

}
  .aboutvvccc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    max-width: 576px;
    width: 100%;
  }
  li.douuttares {
    list-style-type: disc;
    color: #10312B;
   
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.6px */
    margin-left: 15px;
  }
  .aboutvv {
    color: #ff8a00;
   
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 17.6px */
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .myhead {
    color: #fff !important;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  .mypara {
    color: #10312B;
   
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 35.2px */

  }
  p.myparasecsde {
    color: #10312B;
   
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
  .myparahead {
    color: #10312B;
   
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 30.8px */
  }
  .listmain {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 18px;
  }
}
.aboutusimgg_smaet{
  margin: 0 auto;
}

@media (max-width: 600px) {
  .affiliateprogram .mypara {
    margin: 0px 0px 18px;
  }
  .affiliateprogram .myparahead {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .affiliateprogram .listmain {
    margin-bottom: 10px;
    font-size: 14px !important;
  }
}

@media (max-width: 1400px) {
  .smartcityouterdiv{
    flex-direction: column-reverse;
  }
  .aboutusimgg {
    margin-top: 20px;
  }
  // .affiliateprogram {
  //   margin-top: 50px;
  //   margin-bottom: 50px;
  // }
  // .aboutvvccc{
  //   padding-top: 60px;
  // }
  .affiliateprogram .aboutvv {
    font-size: 30px;
  }
  // .affiliateprogram .mypara {
  //   color: var(--SubText, #7a7a7c);
  //   font-size: 16px;

  //   padding-bottom: 20px;
  // }
  .affiliateprogram li.douuttares {
    font-size: 16px;

    margin-left: 0px;
  }
  // .affiliateprogram{
  //   padding: 20px !important;
  // }
  .aboutvvccc{
    width: 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 1400px) {
  .smartcityouterdiv{
    flex-direction: column-reverse;
  }

}
@media (max-width: 900px) {
.smartcityouterdiv{
  flex-direction: column-reverse !important;
  gap: 40px !important;
}
}
