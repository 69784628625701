.ourteam {
  padding-top: 80px;
  padding-bottom: 80px;

  text-align: center;
  background: #fbf7f3;
  ::-webkit-scrollbar {
    display: none;
  }
  .maincarddiv {
    padding: 10px;
  }
  .inercard {
    border: 1px solid #fff;
    padding: 2px;
    border-radius: 12px;
    background: #fff;
    &:hover {
      // border-radius: 12px;
      border: 1px solid #ff8a00;
      background: #fff;
    }
    .body_carddd {
      padding: 15px 10px;
    }
    h1 {
      color: #10312b;
      font-size: 20px;
      text-align: start;
      font-style: normal;
      font-weight: 600 !important;
      margin-bottom: 0px !important;
      line-height: 140%; /* 28px */
    }
    p {
      color: #10312b;
      text-align: start;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }
  .mainouterdivproperties {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    // align-items: center;
    // padding-bottom: 50px;
    .rightfstt_aboutvvccc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      margin-left: 10px;
      .aboutdfdvv {
        color: #ff8a00;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        margin: 0px;
      }
      .myparxxa {
        color: #10312b;

        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        margin: 0px;
      }
      .myparasefrecsde {
        color: #10312b;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        max-width: 654px;
        text-align: start;
        width: 100%;
        margin: 0px;
      }
    }
  }
  h3.teamheading {
    color: var(--Black1, #000);
    text-align: start;

    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    // padding-bottom: 36px;
  }
  .ourteammainouter_divsec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    overflow-y: auto;
    .card {
      border-radius: 12px;
      background: #ffffff;
      .card-body {
        display: flex;
        padding: 15px 10px;
        flex-direction: column;
        align-items: flex-start;
        // gap: 30px;
      }
      .card-title.h5 {
        color: #10312b;
        white-space: nowrap;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 33.6px */
        // margin: 0px;
        text-align: start;
      }
      p.card-text {
        color: #586f6b;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        // padding: 0px 0px 0px 3px;
        text-align: start;
      }
      .incardflexdiv {
        display: flex;
        align-items: center;
        gap: 7px;
        /* align-self: stretch; */
        width: 100%;
        justify-content: space-between;
        .textwithimg {
          display: flex;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          color: #586f6b;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }
      .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* align-self: stretch; */
        padding-top: 15px;
        .cadftrdig {
          color: #10312b;

          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
        .seedetails {
          color: #ff8a00;
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
        }
      }
    }
    .titlecardddssg {
      display: inline-flex;
      padding: 10px 11px;
      justify-content: center;
      align-items: center;
      gap: 7px;
      border-radius: 100px;
      background: #fff;
      color: #ff8a00;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      text-transform: uppercase;
    }
  }
}
.imhgggggcard {
  height: auto;
  width: 100%;
}
@media (max-width: 600px) {
  .ourteam .inercard {
 
    // margin-left: 2px;
    // padding-left: 3px;
 
}
.ourteam .maincarddiv{
  padding: 0 !important;
}
  .ourteam .ourteammainouter_div .card p.card-text {
    font-size: 12px !important;
  }
  .mypara {
    padding-bottom: 0px !important;
  }
  .aboutvvccc {
    gap: 20px !important;
  }
  .myparasefrecsde {
    align-items: center !important;
  }
  .ourteam .ourteammainouter_div .card .card-title.h5 {
    font-size: 16px !important;
  }
  .rightfstt_aboutvvccc {
    padding-top: 50px !important;
  }
  .ourteam .ourteammainouter_div {
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .affiliateprogram .mypara {
    margin: 0px 0px 18px;
  }
  .affiliateprogram .myparahead {
    margin-bottom: 0px;
    font-size: 18px;
  }
  .affiliateprogram .listmain {
    margin-bottom: 10px;
    font-size: 14px !important;
  }
  .inercard {
    // width: 295px !important;
  }
  .ourteam .inercard{
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .ourteam .inercard {
    // max-width: fit-content !important;
    width: 100%;
 
    // padding: 5px;

}
.ourteam .mainouterdivproperties .rightfstt_aboutvvccc{
  margin-left: 0 !important;
}
.ourteam .inercard .body_carddd {
  padding: 15px;
}
  .ourteam .ourteammainouter_div .card .card-title.h5 {
    color: #181818;
    text-align: center;
    /* font-family: Acronym; */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  .aboutdfdvv {
    font-size: 22px !important;
  }
  .ourteammainouter_divsec {
    justify-content: flex-start !important;
  }
  .ourteam .ourteammainouter_div {
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .ourteam .ourteammainouter_div .card p.card-text {
    color: #383751;
    /* font-family: Acronym; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  // .ourteam .ourteammainouter_div {
  //   flex-wrap: wrap;
  //   align-items: center;
  //   justify-content: center;
  // }
  .ourteam {
    /* padding-bottom: 50px; */
    /* padding-top: 50px; */
    text-align: center;
    /* padding-left: unset; */
    padding: 50px 0px 50px 0px;
    /* padding-right: 25px; */
  }

  .ourteam h3.teamheading {
    font-size: 30px;

    padding-bottom: 20px;
  }
}
@media (max-width: 1200px) {
  .ourteammainouter_divsec {
    justify-content: flex-start !important;
  }
}
