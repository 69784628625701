.important_div {
  background: #fbf7f3;
  padding: 10px;
}

.main-banner {
  margin: 0px auto;
  // border-radius: 20px;
  background: linear-gradient(180deg, #0E2545 0%, #1E4B7A 100%);
  background-image: url("../../../Assests/Bannar/banerbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  // padding-top: 50px;
  height: 100vh;
  padding-bottom: 25px;
  // padding-top: 20px;
  .onlyforflex {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    // flex-wrap: wrap;
 
    padding-bottom: 135px;
    .leftside_3D {
      max-width: 800px;
      height: 700px;
      flex-shrink: 0;
      width: 100%;
    }
  }
  .mintbutton {
    color: #ff8a00;
    border: 1px solid #ff8a00;
    background-color: transparent;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    text-transform: uppercase;
    padding: 12px 24px;
    border-radius: 37px;
    &:hover {
      color: #fff;
      // background: #0f1a1e;
      transition: 0.5s ease-in-out;
      background: transparent;
      // border-radius: 6px;
      // border: 1.5px solid #0076F9;
      border: 1.5px solid #ff8a00;
      background-color: #ff8a00;
    }
  }

  .maincenterdiv {
    // display: flex;
    // max-width: 560px;
    // width: 100%;
    // justify-content: center;
    // flex-direction: column;
    // align-items: flex-start;
    // gap: 30px;
    // min-height: 100vh;

    display: flex;
    max-width: 560px;
    width: 100%;
    // padding-top: 200px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    min-height: 100vh;
    // width: 1110px;
    height: 256px;
    padding-top: 135px;

    button.explormnkjhore {
      border-radius: 183px;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.09);
      box-shadow: 38.467px -38.467px 38.467px 0px rgba(194, 194, 194, 0.09) inset,
        -38.467px 38.467px 38.467px 0px rgba(255, 255, 255, 0.09) inset;
      backdrop-filter: blur(4.616000175476074px);
      display: flex;
      /* width: 191px; */
      padding: 10px 30px;
      /* flex-direction: column; */
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--White, #fff);
      text-align: center;
      /* font-family: Acronym; */
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .pprotocoldd_new {
      border-radius: 130px;
      border: 1.5px solid rgba(255, 255, 255, 0.12);
      background: rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(2.5px);
      white-space: nowrap;
      display: flex;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 17.6px */
    }
    p.parafirstff {
      color: #fff;

      font-size: 20px;
      font-style: normal;
      font-weight: 500 !important;
      line-height: 120%; /* 24px */

    }
    .parafirstcc {
      color: #fff;

      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 50px */
      text-transform: capitalize;
      margin: 0px;
    }
  }
  .tomi-pink {
    color: #1777e2;
  }

  .button-banneerr {
    transition: 0.5s ease-in-out;
    outline: none !important;
    border: none;
    border-radius: 60px;

    background: linear-gradient(90deg, #1e1cb0 0%, #00c9ff 100%);
    color: #fff;
    font-family: Tbold;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    text-transform: uppercase;
    padding: 22px 31px;
    cursor: pointer;

    // margin-left: 41px;

    /* identical to box height, or 16px */

    &:hover {
      color: #ffffff;
      background: #0f1a1e;
      transition: 0.5s ease-in-out;
      // box-shadow: 0px 4px 9px #1777e233;
      // border-radius: 6px;
      // border: 1.5px solid #0076F9;
    }
  }
  .inner-content.main-heading {
    // margin-top: 147px;
    // margin-bottom: 40px;
    p.pprotocol {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      text-transform: uppercase;
      color: #1777e2;
      padding-bottom: 25px;
    }
    h3 {
      font-family: "Archivo", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 120%;
      text-align: center;
      color: #121212;
    }
    h2.parafirstcc {
      color: #fff;
      font-family: Tbold;
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
    // p.parafirstff {
    //   color: #fff;
    //   font-family: Poppins;
    //   font-size: 18px;
    //   font-style: normal;
    //   font-weight: 400;
    //   line-height: 140%; /* 25.2px */
    //   // padding-top: 25px;
    //   // padding-bottom: 25px;
    //   // margin-bottom: 25px;
    // }
    .btn.button-hedaerss {
      background: #1777e2;
      border-radius: 6px;
      padding: 13px 25px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;
      &:hover {
        background: #0076f9;
        box-shadow: 0px 4px 9px #1777e233;
        border-radius: 6px;
      }
    }
    .btn.button-hedaersss {
      background: #111213;
      border: 1px solid #2c2c2c;
      border-radius: 6px;
      align-items: center;
      padding: 16px 25px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 100%;
      margin-left: 12px;
      color: #1777e2;
      &:hover {
        background: #141618;
        border: 1px solid #1777e2;
        border-radius: 6px;
      }
    }
  }

  .modal-content {
    border-radius: 20px;
    .for-padding {
      padding: 0 50px;
    }
    .form-group {
      input {
        background: #ffffff;
        border: 1px solid #d4d4d4;
        box-sizing: border-box;
        border-radius: 10px;
        height: 45px;
        padding: 6px 12px;
      }
    }
    .btn-common {
      width: 100%;
    }
  }
}

.counter {
  background: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 991px) {

  .main-banner .inner-content {
    min-height: 80vh;
  }
  .main-banner .inner-content p.parafirstff {
    color: var(--White, #fff);
    text-align: center;

    font-size: 15px;
    max-width: 872px;
    width: 75%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .parafirstcc {
    font-size: 33px !important;
  }
  .parafirstff {
    font-size: 16px !important;
  }
  .main-banner .inner-content .pprotocoldds {
    top: -30px;
    left: 200px;

    height: unset;
    transform: rotate(-5.253deg);
    padding: 10px;

    font-size: 15px;
    width: auto;
  }
  .main-banner .inner-content .parafirstcc {
    font-size: 20px;
    // width: 80%;
    // margin: 0px auto;
  }
  .onlysformobile {
    padding: 0px !important;
  }
  .main-banner .input-field {
    width: 100%;
  }
  .main-banner .inner-content.main-heading.text-center h3 {
    font-size: 45px !important;
  }
  .maincenterdiv {
    padding-top: 72px !important;
  }
}
@media only screen and (max-width: 600px) {
  .main-banner {
    background-image: url("../../../Assests/Bannar/phonebg.png");
  }
  .important_div {
    padding: 5px !important;
  }
  .main-banner {
    .w-50 {
      width: 100% !important;
    }


    .input-group-text {
      background: #1777e2;
      color: #ffffff;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
      border-radius: 0px 20px 20px 0px;
      padding: 10px 10px;
    }
  }
  .main-banner .parafirst p {
    margin: unset !important;
    margin-bottom: 56px !important;
    font-size: 15px !important;
  }
}

.abb {
  outline: none !important;
  box-shadow: none !important;
}

@media (max-width: 600px) {
  .main-banner .input-field .btn-searchbar {
    position: unset !important;
    margin-top: 20px;
    width: 100%;
  }
  .main-banner {
    // padding-bottom: 39px;
  }
}

@media (max-width: 430px) {
  .main-banner .input-field input {
    padding-left: 50px;
  }
  .main-banner .inner-content.main-heading.text-center h3 {
    font-size: 26px !important;
  }
  .main-banner .inner-content.main-heading.text-center h3 {
    font-size: 24px !important;
  }
}

@media (max-width: 1100px) {
  .main-banner .inner-content .maincenterdiv {
    width: 80%;

    margin: 0px auto;
  }
  // .maincenterdiv{
  //   padding: 20px !important;
  // }

  .main-banner .onlyforflex {

    flex-wrap: wrap;
    gap: 15px;
 
    padding-bottom: 35px;
    margin: 0px auto;
}
}

@media (max-width: 800px) {
  .main-banner .maincenterdiv {
 
    max-width: 50%;
    width: 100%;
    gap: 20px;
    min-height: auto;
}
  img.img-fluid.header_img {
    margin-right: unset;
    margin-top: -82px;
    margin-bottom: -100px;
  }
  .inner-content.main-heading {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    // gap: 25px;
  }
  .main-banner .inner-content.main-heading p.pprotocol {
    font-size: 14px;
    line-height: 15px;

    padding-bottom: 20px;
  }
  .main-banner .inner-content.main-heading h2.parafirstcc {
    color: #fff;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 39px */
    text-transform: uppercase;
    text-align: start;
  }
  .main-banner .inner-content.main-heading p.parafirstff {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }
  .lastbtnbnr {
    display: flex;
    align-items: center;
    margin-bottom: 42px;
    justify-content: center;
  }
  .main-banner .inner-content.main-heading button.btn.button-hedaerss {
    padding: 12px 12px;

    font-size: 14px;
    line-height: 100%;
  }
  .main-banner .inner-content.main-heading button.btn.button-hedaersss {
    // padding: 12px 20px;

    font-size: 14px;
    line-height: 100%;
  }
  .main-banner {
    margin-top: unset;
  }
}

@media (max-width: 600px) {
  .main-banner .maincenterdiv {
 
    max-width: 100% !important;
    width: 100%;
    gap: 20px;
    min-height: auto;
}
  .main-banner .onlyforflex .leftside_3D {

    width: 100%;
    max-width: 419px;
    height: 277px;
}
  .main-banner .inner-content p.parafirstff {
    margin: 0px auto;
    width: 80%;
  }
  .maincenterdiv {
    width: 80%;
  }
  .main-banner .inner-content .pprotocoldds {
    top: -22px;
    left: 84px;
    height: unset;
    transform: rotate(-5.253deg);
    padding: 9px;
    font-size: 12px;
    width: auto;
  }
  .main-banner .inner-content.main-heading .btn.button-hedaerss {
    width: 100%;
    flex: 0 0 100%;
  }
  .main-banner .inner-content.main-heading .btn.button-hedaersss {
    margin: 0 auto;
    flex: 0 0 47%;
  }
}
