.affiliateprogram_info {
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  border-radius: 20px;
  background: #0b231f;
  margin: 0px 10px;

  .Bbenefits {
    .toperparraa {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 60px;

      .besthngd {
        color: #fff;
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        line-height: 110%;
        padding-top: 16px;
        padding-bottom: 30px;
      }

      .seccsparaa {
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        width: 65%;
      }
    }
  }

  .cousallmodaldiv {
    .modal-content {
      background: transparent;
    }
  }
}

.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg,
.image-gallery-fullscreen-button .image-gallery-svg {
  display: none;
}

.image-gallery-slide .image-gallery-image {
  width: 100% !important;
  height: 755px !important;
  object-fit: cover !important;
  object-position: center !important;
  border-radius: 10px !important;
}

.image-gallery-thumbnail-image {
  vertical-align: middle !important;
  width: 100% !important;
  line-height: 0 !important;
  height: 98px;
  border-radius: 10px !important;
  outline: unset !important;
  padding-right: 3px;
  padding-left: 3px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover {
  outline: unset !important;
  border: none !important;
}

.image-gallery .image-gallery-thumbnail {
  transform: none !important;
  transition: none !important;
  border: none !important;
}

.image-gallery .image-gallery-thumbnail img {
  cursor: pointer;
}

.cousallmodaldiv .modal-content {
  background: transparent;
}

.infor_main {
  background: #fbf7f3;
}

.mainbannerttinfo {
  max-width: 1500px;
  width: 100%;
  position: relative;

  .shadowandbrn {
    position: absolute;
    bottom: 0px;
    padding: 261px 0px 20px 0px;
    background-image: url("../../../Assests/smartcity/smartcitybg.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 361px;
    width: 100%;
    left: -1.088px;
  }

  .incards_outerdivmain {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 12px;

    .aboutusimgg {
      display: flex;
      width: 100%;
      height: 262px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 0 19%;

      .incardrimg_all {
        width: 100%;
        border-radius: 12px;
        height: 100%;
      }
    }
  }
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {

  margin-top: 10px !important;
}
@media (max-width: 600px) {
  .affiliateprogram .mypara {
    margin: 0px 0px 18px;
  }
  .image-gallery-thumbnail-image {
    vertical-align: middle !important;
    width: 100% !important;
    line-height: 0 !important;
    height: 77px;
    border-radius: 10px !important;
    outline: unset !important;
  }

  .affiliateprogram .myparahead {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .myyyyrow {
    padding: 20px !important;
  }

  .affiliateprogram .listmain {
    margin-bottom: 10px;
    font-size: 14px !important;
  }
}

@media (max-width: 800px) {
  .affiliateprogram_info {
    padding-top: 50px;
    text-align: center;

    .Bbenefits .toperparraa {
      padding-bottom: 50px;

      p.seccsparaa {
        font-size: 16px;
        width: 100%;
      }

      .besthngd {
        font-size: 30px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .mainbanner_info .rightfstt_aboutvvccc .aboutvvcvx {
    color: var(--White, #fff);
    font-size: 30px;
    padding-top: 30px;
  }

  .image-gallery-slide .image-gallery-image {
    width: 100% !important;
    height: 295px !important;
    object-fit: cover !important;
    object-position: center !important;
    border-radius: 10px !important;
  }
}

@media (max-width: 1000px) {
  .mainbanner_info {
    padding: 50px 0px 50px 20px;

    .rightfstt_aboutvvccc .myparasdd {
      color: var(--White, #fff);
      font-size: 16px;
    }
  }

  .incardrimg_all {
    width: 279.891px !important;
    height: 262px !important;
  }

  .incards_outerdivmain {
    justify-content: center !important;
  }
}
