.main-button-mm.collapsed {
  .mmmm {
    display: none;
  }
  .mmm {
    display: block;
  }
}
.main_faqssssss {
  display: flex;
  gap: 90px;
}

.gggtrre{

  padding-top: 0px !important;
}
.new_lasttt {
  background: #fbf7f3;
}
.main-button-mm {
  .mmm {
    display: none;
    color: #000;
    span.plussss {
      color: #b855ff;
    }
  }
  .mmmm {
    display: block;
  }
}
.acordingouterdiv {
  // border: 1px solid transparent;
  // border-radius: 10px;
  border: unset;
  overflow: hidden;
}
.inner-main-right {
  .minus-sign {
    // font-size: 40px !important;
    color: #ffffff;
    font-weight: 300 !important;
  }
}

// .main-button-mm{
//     .mmm{
//         display: block;
//     }
// }
.highlightsxxxx {
  display: flex;
  width: 550px;
  flex-direction: column;
  align-items: flex-start;
  .aboutdfdvv {
    color: #ff8a00;
    // font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
  }
  p.common.highlightsheading {
    // font-family: "Chakra Petch";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: #1777e2;
    // margin-bottom: 20px;
  }
  h2.Pioneersss {
    color: #10312b;
    // font-family: Aeonik;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 35.2px */
    padding-top: 16px;
    padding-bottom: 30px;
    margin: 0px;
  }
  p.faqs_para {
    color: #10312b;
    // font-family: Aeonik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.6px */ /* 28.8px */
    span.inerparaspan {
      color: var(--Blue-600, #4c7ce5);
      // font-family: Acronym;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
    }
  }
}
.mysett {
  padding-top: 80px;
  padding-bottom: 80px;
  border-radius: 20px;

  background-color: #fff;
}

.main-faqs {
  /* padding-top: 71px; */

  background-color: #fbf7f3 !important;
  // padding-bottom: 20px;
  margin: 0px 10px;

  // height: 80vh;
  button.accordion-button.collapsed {
    border-radius: 12px 12px 0px 0px;
    border: 1px solid #efefef;
    background: #fff;
    &:hover {
      border: 1px solid transparent;
    }
  }
  .accordion-item {
    color: #10312b;
    // font-family: Aeonik;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    border: 1px solid transparent;
    line-height: 140%; /* 19.6px */
    border: 1px solid #efefef;

    // border-radius: 0px 0px 12px 12px;
    // border-right: 1px solid #EFEFEF;
    // border-bottom: 1px solid #EFEFEF;
    // border-left: 1px solid #EFEFEF;
    // background: #FFFEFC;
    // padding: 18px;
    &:hover {
      border: 1px solid #ff8a00;
    }
  }
  .accordion-button:focus {
    z-index: 3;
    outline: none;
    box-shadow: none !important;
    border: none;
  }
  h2.accordion-header {
    background: #f3f3f3;
    background: #f3f3f3;
    color: var(--Black1, #000);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    box-shadow: none;
    border: unset;
    border-radius: 10px;
  }
  .accordion-button {
    color: #10312b;
    /* font-family: Aeonik; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  .accordion-button:not(.collapsed) {
    color: #10312b;
    /* font-family: Aeonik; */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    box-shadow: none;
    border-radius: 12px 12px 0px 0px;
    border: 1px solid transparent;
    background: #fff;
  }
  .accordion-body {
    color: var(--SubText, #7a7a7c);
    // font-family: Acronym;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    outline: none;
    box-shadow: none;
    border-top: 1px solid #efefef;
    border-radius: 0px 0px 10px 10px;

    background: #fffefc;
  }
  .accordion-collapse.collapse.show {
    background: #fffefc;
    // padding: 0px 0px 20px 18px;
    color: #586f6b;
    /* font-family: Aeonik; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
  // .accordion-button:not(.collapsed)::before {

  //   background-image: url("../../../../src/Assests/faqs/Add_Plus.svg");
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }
  // .accordion-button:not(.collapsed)::after {

  //   background-image: url("../../../../src/Assests/faqs/Remove_Minus.svg");
  //   background-position: center;
  //   background-repeat: no-repeat;
  // }
  /* For the collapsed state (showing the plus icon) */
  .accordion-button:not(.collapsed)::after {
    content: ""; /* Ensure the pseudo-element displays */
    background-image: url("../../../../src/Assests/faqs/Remove_Minus.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 1.5rem; /* Adjust width according to your icon size */
    height: 1.5rem; /* Adjust height according to your icon size */
    position: absolute; /* Position the icon absolutely */
    right: 1rem; /* Adjust this value to move the icon further to the right */
    top: 50%; /* Center the icon vertically */
    transform: translateY(-50%); /* Adjust for vertical centering */
  }

  /* For the expanded state (showing the minus icon) */
  .accordion-button.collapsed::after {
    content: ""; /* Ensure the pseudo-element displays */
    background-image: url("../../../../src/Assests/faqs/Add_Plus.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 1.5rem; /* Adjust width according to your icon size */
    height: 1.5rem; /* Adjust height according to your icon size */
    position: absolute; /* Position the icon absolutely */
    right: 1rem; /* Adjust this value to move the icon further to the right */
    top: 50%; /* Center the icon vertically */
    transform: translateY(-50%); /* Adjust for vertical centering */
  }

  /* Hide the default caret icon that Bootstrap adds */
  // .accordion-button::after {
  //   display: none;
  // }

  .main-dd {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    width: 100%;
    .inner-main-left {
      display: flex;
      justify-content: start;
      // align-items: center;
      // padding: 30px 0px 10px 30px;
      .inner-left {
        padding-right: 10px;
        span {
          // font-family: "Chakra Petch";
          font-style: normal;
          // font-weight: 600;
          font-size: 18px !important;
          line-height: 130%;
          color: #1777e2;
        }
      }
      .inner-right {
        h5 {
          // font-family: "Chakra Petch" !important;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 130%;
          color: #000000;
        }
      }
    }
    .inner-main-right {
      padding-left: 10px;
      p {
        // font-family: "Chakra Petch";
        font-style: normal;
        // font-weight: 600;
        font-size: 30px !important;
        line-height: 130%;
        color: #1777e2;
      }
    }
  }
  .inner-upper {
    z-index: 150;
    width: 100%;
  }
  .ptb {
    padding: 80px 0 !important;
  }
  .inner-side {
    width: 100%;
    .card {
      background: transparent;
      // // font-family: "Chakra Petch" !important;
      // font-style: normal;
      // font-weight: 500;
      // font-size: 20px;
      // line-height: 130%;
      // color: #111216;
      // // margin-bottom: 20px;
      // border-bottom: 1px solid #000000 !important;
      // margin-bottom: 5px;
      // border: aliceblue;
      // border: none !important;
      // border-style: solid;
      // border-color: #1777E2;
      &:first-child {
        // border-top: 2px solid #ffffff1a;
        // border-radius : 10px solid (90deg, #020024 0%, #ff00e0 100%, #00d4ff 100%);
      }
      // .card.gradient-box {
      //     border-style: solid;
      //     border-color: red #f01e32b3 green;
      // }
      .card-header {
        padding: 0px;
        // font-family: "Chakra Petch" !important;
        border-bottom: none;
        .btn {
          color: #ffffff;
          width: 100%;
          text-align: left;
          padding-left: 0;
          white-space: unset !important;
          text-decoration: none;
          border-radius: 8px 8px 8px 8px;
        }
        .fa-plus,
        .fa-minus {
          color: #f88d21;
        }
        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
        }
      }
    }
  }
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    // color: var(--bs-btn-active-color);
    // background-color: var(--bs-btn-active-bg);
    border-color: transparent !important;
  }
  .main-tile {
    margin-bottom: 10px;
    margin-top: 40px;
    // background: #f7f9fb;
    border: 2px solid #ffffff1a;
    box-sizing: border-box;
    // backdrop-filter: blur(50px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
    padding: 30px 20px;
    border: transparent;
    // height:301px;
    &:hover {
      // box-shadow: 5px 5px 30px #f07a3d33;
      border: 2px solid #ffffff1a;
      // border: 2px solid transparent;
    }
  }
  .inner-content {
    .svss {
      // font-family: "Chakra Petch" !important;
      font-style: normal;
      // font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #a3a3a3;
      padding-top: 15px;
      padding-bottom: 70.5px;
    }
    .shdbshb {
      // font-family: "Chakra Petch" !important;
      font-style: normal;
      // font-weight: 700;
      font-size: 40px;
      line-height: 110%;
      text-transform: uppercase;
      color: #000000;
    }
    img {
      border-radius: 10px;
    }
  }
  .inner-side .card .card-header {
    // background-color: #ffffff;
    // border: 0px 0px 38px 54px;
    // border-radius: 10px 10px 10px 10px;
    // font-family: "Chakra Petch" !important;
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;

      color: #ffffff;
      span.diggits {
        background: linear-gradient(141.48deg, #b855ff 12.54%, #5e32cb 72.36%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        padding-right: 10px;
      }
    }
    p {
      // font-family: "Gellix";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #dadff5;
    }
  }
  .card-body {
    // padding-top: 30px;
    /* padding-left: 67px; */
    /* padding-bottom: 30px; */
    /* padding-right: 35px; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #8f8f8f;
    /* background: beige; */
    // background: #111213;
    // border-width: 0px 1px 1px 1px;
    // border-style: solid;
    // border-color: #1e1f20;
    border-radius: 0px 0px 8px 8px;
    padding: 30px;

    background: transparent;
    border-bottom: 1px solid #1e1e1e;
    // border-radius: 8px 8px 8px 8px;
  }
}
.main-faqs .inner-side .card .card-header .btn {
  padding: 0px;
}
.main-faqs .main-dd {
  // padding: 15px 0px;
}
.btn.focus,
.btn:focus {
  box-shadow: unset !important;
}
@media (max-width: 600px) {
  .main-faqs {
    padding-bottom: 0px;
  }
  .inner-side {
    .card {
      .card-header h5 {
        font-size: 14px;
      }
    }
  }
  .textlast{
    flex-wrap: wrap !important;
    width: 220px !important;
    padding-top: 20px !important;
  }
  button.accordion-button {
    padding: 0px ;
    padding-bottom: 20px;
  }  
  span.digits.mx-3 {
    padding-top: 20px;
}
}

.card-header:first-child {
  border-radius: 0px !important;
}
.main-faqs .inner-side .card .card-header {
  // border-radius: 0px !important;
  // background-color: #faf6ff;
  // border-bottom: 1px solid #DADADA;
}

.card {
  // border-radius: 0px !important;
  // background-color: #faf6ff;
  // border-bottom: 1px solid #dadada;
}

.openclose .collapse.show {
  // background-color: #faf6ff;
  // border-bottom: 1.5px solid #dadada;
  // border-top: 0px;
}
.openclose .collapsing {
  // background-color: #730cf2 !important;
  border: none;
  border-top: 0px;
}
.main-button-mm {
  background-color: transparent !important;
  // border: 0px 0px 38px 54px;
  // font-family: "Chakra Petch" !important;
  // padding: 35px 30px !important;
  padding-bottom: 20px !important;
  // font-family: "Chakra Petch" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 17px !important;

  color: #ffffff !important;

  padding: 27px 30px !important;

  border-bottom: 1px solid #1e1f20;
  // border-radius: 8px 8px 0px 0px;
}

// .main-button-mm.collapsed {
//     background-color: #ffffff;

//     padding: 35px 30px;
//     background: transparent !important;
//     // font-family: "Chakra Petch" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     font-size: 20px !important;
//     line-height: 130% !important;
//     // border-color: #ffffff !important;
//     color: #111216 !important;
//     // border-style: solid !important;
//     border-radius: 0px !important;
// }

.card-body {
  transition: unset !important;
}
.collapse.show {
  transition: unset !important;
}

@media (max-width: 1100px) {
  .main-faqs {
    /* padding-top: 71px; */

    // margin-top: 122px;
    // margin-bottom: 122px;
    // height: 90vh;
  }
  .highlightsxxxx h2.Pioneersss {
    font-size: 30px !important;
    text-align: start;

    // line-height: 30px;

    // padding-bottom: 22px;
  }
  .aboutdfdvv {
    text-align: start !important;
  }
  .highlightsxxxx {
    text-align: start !important;
    align-items: flex-start !important;
  }

  .main_faqssssss {
    flex-direction: column !important;
    gap: 0px !important;
  }
  .highlightsxxxx {
    width: 100% !important;
  }
  .mysett {
    margin-top: 50px;
    padding-top: 40px;
    padding-bottom: 20px !important;
  }

  // .main-faqs {

  //     height: 100vh;
  // }
}

@media (max-width: 992px) {
  .main-faqs {
    height: 100%;
  }
  .highlightsxxxx p.faqs_para {
    // font-size: 12px;
    // width: 80%;
    margin: 0px auto;
    padding-bottom: 15px;
  }
}

@media (max-width: 800px) {
  .main-faqs {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .main-faqs .highlightsxxxx {
    text-align: center;
    padding-bottom: 20px;
    align-items: center;
  }
  .main-faqs .main-dd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .main-faqs {
    margin-top: 0;
  }
}
