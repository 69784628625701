.Riyadh_vil {
  background-color: rgb(255, 255, 255);
  padding-bottom: 80px;
  padding-top: 0px;
  height: auto;

  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;

  .imgdivv {
    // max-width: 532px;
    // width: 100%;
    // height: 404px;
  }
  .inner_div {
    display: flex;
    // margin-top: 40px;
    justify-content: center;
    // margin: 0px 210px;
    // border-radius: 20px;
    // border: 1px solid rgba(16, 49, 43, 0.08);
    background: #fff;
    overflow: hidden;
  }
  h3 {
    color: #000;
    text-align: center;
    // padding-bottom: 20px;
    font-size: 40px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  .pirceinerdiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .fstminbld {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .mntgp {
        color: #10312b;
        text-align: center;
        /* font-family: Aeonik; */
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
      p.mntgpcv {
        color: #10312b;
        text-align: center;
        /* font-family: Aeonik; */
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }
  .mintingimggg {
    width: 100%;
  }
  .imgdivv {
    width: 100%;
  }
  .muttedds {
    color: #6f827f;
    /* font-family: Aeonik; */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  .right_side {
    // max-width: 532px;
    // width: 100%;
    // // height: 404px;

    // background: #fff;
    // display: flex;
    // // width: 532px;
    // padding: 40px 50px;
    // flex-direction: column;
    // align-items: center;
    // gap: 30px;
    // // border: 0.5px solid ;
    .outerdivleft_fst {
      display: flex;
      padding: 25px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 35px;
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      background: #fff;
      .outerdivleft_iner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        .outerdivleft_para {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
          /* flex: 1 0 0; */
          h1.mainhnd {
            color: #10312b;
            font-family: Rajdhani;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: 90%;
            margin: 0px;
          }
          .inerrara {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #586f6b;
            text-align: center;
            /* font-family: Aeonik; */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: capitalize;
          }
        }
        .outerdivleft_parasec {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
          .commonparaas {
            color: #586f6b;
            text-align: center;
            font-family: Rajdhani;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: capitalize;
          }
          .commdig {
            color: #ff8a00;
            font-family: Rajdhani;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 90%;
            margin: 0px;
          }
        }
      }
    }
    .outerdivleft_sec {
      display: flex;
      padding: 25px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 35px;
      border-radius: 12px;
      border: 1px solid #e6e6e6;
      background: #fff;
      margin-top: 10px;
      .itofst {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        h4.inersechnd {
          color: #10312b;

          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          margin: 0px;
          line-height: 90%;
        }
        .commonparaas {
          color: #586f6b;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
      .icommnsa {
        display: flex;
        align-items: flex-start;
        gap: 40px;
        .iconnsmaimdiv {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 11px;
          .commonparaas_icon {
            color: #10312b;

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 90%;
          }
          .commonparaasdds {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #586f6b;
            font-family: Rajdhani;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
          }
        }
      }
      .center_line {
        width: 100%;
        border: 1px solid #e6e6e6;
      }
    }
    .gerala_div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      .ullliiss {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .fordesign {
          list-style: disc;
          color: #586f6b;
          /* font-family: Aeonik; */
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }
  }

  .partsecond {
    display: flex;
    gap: 10px;
    // margin-top: 22px;
    align-items: center;
  }
  .plusssdiv {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    border-radius: 56px;
    border: 2px solid #ebebeb;
    background: #fff;
    gap: 10px;
    margin-top: 0px;
  }
  h5 {
    width: 13.72px;
    height: 13.776px;
  }
  .inputmint {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    height: 80px;
    margin-bottom: 0px;
    border-radius: 61px;
    border-radius: 100px;
    border: 2px solid #ebebeb;
    background: #fff;
    text-align: center;

    text-align: center;
    color: #10312b;

    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    ::placeholder {
      color: #10312b;
      text-align: center;

      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      align-self: stretch;
      text-align: center;
    }
  }
  .commonbtnorgss {
    color: #fff;
    text-align: center;

    font-style: normal;
    border-radius: 60px;
    background: #ff8a00;

    border: unset;
    width: 100%;
    display: flex;
    padding: 30px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #fff;
    text-align: center;

    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.88px;
    text-transform: capitalize;
  }
  .headtex {
    color: #000;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600 !important;
    background-color: unset;
    line-height: normal;
    text-transform: capitalize;
    white-space: nowrap;
  }
}
.leftrightmarginonly {
  // margin: 0px 17px;
}
.Riyadh_vil_btmm {
  .ourteammainouter_divvilla {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // gap: 14px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    .maincarddiv {
      // width: 100%;
      border-radius: 12px;
      background: #fff;
      position: relative;
      padding: 2px;
      border: 1px solid #fff;
      &:hover {
        border: 1px solid #ff8a00;
      }
      .imgcardddds {
        width: 100%;
        height: auto;
      }
      .zoom-container {
        position: relative;
        width: 100%; /* Adjust width as needed */
        overflow: hidden; /* Hide the part of the image that exceeds the container */
      }

      /* Image styling */
      .imgcardddds {
        display: block;
        width: 100%;

        transition: transform 0.5s ease; /* Smooth transition for the zoom effect */
      }

      /* Zoom effect on hover */
      .zoom-container:hover .imgcardddds {
        transform: scale(1.2); /* Adjust scale factor as needed */
      }
      .inercard_dta {
        padding: 15px 10px;
      }

      h1 {
        color: #10312b;
        text-align: start;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 0px;
        line-height: 140%;
        /* 33.6px */
      }

      p {
        color: #586f6b;
        text-align: start;
        padding-bottom: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        padding-top: 3px;
      }

      .iner_mainsssss {
        display: flex;
        align-items: center;
        gap: 50px;
        justify-content: center;
      }

      h2 {
        color: #586f6b;
        margin-bottom: 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 14px */
      }

      .cardds {
        display: flex;

        align-items: center;
        gap: 10px;
      }

      .imgsvgss {
        width: 24px;
        height: 24px;
      }

      .botttom_side {
        margin-top: 15px;
        border-top: 1px solid #efefef;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      h3 {
        color: #10312b;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 30.8px */
      }

      button {
        color: #ff8a00;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 7px;
        background-color: unset;
        border: unset;
        outline: unset;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 14.4px */
        text-transform: uppercase;
      }
    }

    .card {
      border-radius: 12px;
      background-color: #fff;

      .card-body {
        transition: unset !important;
        display: flex;
        padding: 15px 10px;
        flex-direction: column;
        // align-items: flex-start;
        // gap: 30px;
        align-self: stretch;
      }

      .card-title.h5 {
        color: #10312b;

        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 33.6px */
        // margin: 0px;
        text-align: start;
      }

      p.card-text {
        color: #586f6b;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        // padding: 0px 0px 0px 3px;
        text-align: start;
      }

      .incardflexdiv {
        display: flex;
        align-items: center;
        gap: 7px;
        /* align-self: stretch; */
        width: 100%;
        justify-content: space-between;

        .textwithimg {
          display: flex;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          color: #586f6b;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }

      .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* align-self: stretch; */
        padding-top: 15px;
        background-color: #fff;

        .cadftrdig {
          color: #10312b;

          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        .seedetails {
          color: #ff8a00;
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
        }
      }
    }

    .titlecardddssg {
      display: inline-flex;
      padding: 10px 11px;
      justify-content: center;
      align-items: center;
      gap: 7px;
      border-radius: 100px;
      background: #fff;
      color: #ff8a00;
      text-align: center;

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      text-transform: uppercase;
    }
  }
}
@media (max-width: 1200px) {
  .right_side {
    width: 100% !important;
  }
  .inner_div {
    margin: 0px !important;
  }
  .Riyadh_vil {
    height: max-content;

    padding: 30px;
    // margin: -25px 0px 40px 0px;

    width: 100%;
    border-radius: 0px;
  }
  .Riyadh_vil .inner_div {
    flex-direction: column;
  }

  .Riyadh_vil {
    .imgdivv {
      width: 100%;
      height: unset;
    }
    h4 {
      font-size: 18px;
    }
    .plusssdiv {
      height: 40px;
      width: 40px;
    }
    h6 {
      height: 40px;
    }
    button {
      height: 60px;
      font-size: 18px;
    }
    .headtex {
      font-size: 20px;
    }
  }
}
@media (max-width: 850px) {
  .ourteammainouter_divvilla {
    grid-template-columns: 1fr 1fr !important;
    flex-wrap: wrap;
  }
  .Riyadh_vil {
    .inner_div {
      flex-direction: column;
    }
    .right_side {
      width: 100%;
      margin-top: 20px;
    }
    
    h2 {
      font-size: 35px;
      padding-top: 20px;
    }
  }
  .padingzero{
    padding: 0px !important;
  }
}
@media (max-width: 650px) {
  .ourteammainouter_divvilla {
    grid-template-columns: 1fr  !important;
    flex-wrap: wrap;
  }
  .Riyadh_vil .rigDHBTNN {
    font-size: 18px;

    width: fit-content;

    white-space: nowrap;
  }
  .Riyadh_vil .commonbtnorgss {
    padding: 7px 7px !important;
    white-space: nowrap;
    width: 100% !important;
  }
  .right_side {
    padding: 40px 33px !important;
  }
  .Riyadh_vil .inputmint {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex: 1 0;
    height: auto;
    margin-bottom: 0px;
    border-radius: 61px;
    border: 1px solid #000;
    background: #fff;
    text-align: center;
    color: #000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
}
