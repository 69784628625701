.affiliateprogram {
  background-image: url(../../../Assests/Bannar/background-texture.svg) !important;
  padding-top: 80px;
  padding-bottom: 80px;
  background: #FBF7F3;
  .left-img{
    border-radius: 30px;
    // padding-left: 25px !important;
    // margin-left: 0px !important;
    // max-width: 624px;
    // height: auto;
    // width: 100%;
    // margin: 0px auto;

  }
  .mainouterdivabout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    // flex-wrap: wrap;
}
  .aboutusimgg {
    width: 100%;
    margin: 0px auto;
    text-align: center;
}

  .aboutvvccc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
  }
  li.douuttares {
    list-style-type: disc;
    color: #10312B;
 
    font-size: 18px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 120%; /* 21.6px */
    margin-left: 15px;
  }
  .aboutvv {
    color: #ff8a00;
 
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 17.6px */
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .myhead {
    color: #fff !important;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  .mypara {
    color: #10312B;
 
    font-size: 32px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 110%; /* 35.2px */

  }
  p.myparasecsde {
    color: #10312B;
 
    font-size: 18px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 120%;
}
  .myparahead {
    color: #10312B;
 
    font-size: 28px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 110%; /* 30.8px */
  }
  .listmain {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-bottom: 18px;
  }
}

@media (max-width: 600px) {
  .affiliateprogram .mypara {
    margin: 0px 0px 18px;
  }
  .affiliateprogram .myparahead {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .affiliateprogram .listmain {
    margin-bottom: 10px;
    font-size: 14px !important;
  }
}

@media (max-width: 800px) {
  .mainouterdivabout {
  
    flex-wrap: wrap;
}
.affiliateprogram{
  padding: 0px 10px;
}
.mypara{
  font-size: 24px !important;
}
.affiliateprogram {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
  .aboutusimgg {
    margin-top: 20px;
  }
  .affiliateprogram {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .affiliateprogram .aboutvv {
    font-size: 30px;
  }
  .affiliateprogram .mypara {
    color: var(--SubText, #7a7a7c);
    font-size: 16px;

    padding-bottom: 20px;
  }
  .affiliateprogram li.douuttares {
    font-size: 16px;

    margin-left: 0px;
  }
}
